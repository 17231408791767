import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fixedPay", "variablePay", "totalPay"];

  updateTotalPay() {
    const fixedPay = this.fixedPayTarget.valueAsNumber;
    const variablePay = this.variablePayTarget.valueAsNumber;

    if (Number.isNaN(fixedPay) && Number.isNaN(variablePay)) {
      this.totalPayTarget.value = "";
    } else {
      this.totalPayTarget.value = (Number.isNaN(fixedPay) ? 0 : fixedPay)
        + (Number.isNaN(variablePay) ? 0 : variablePay);
    }
  }

  clearFixedVariablePay() {
    this.fixedPayTarget.value = "";
    this.variablePayTarget.value = "";
  }
}
