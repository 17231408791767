import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["age", "birthday"];

  connect() {
    this.update();
  }

  update() {
    this.ageTarget.innerHTML = this.constructor.ageString(this.birthdayTarget.value);
  }

  static ageString(birthday) {
    if (!birthday) return "";

    // Using same calculation as server-side in Common::Services::Age.call()
    const dob = new Date(birthday);
    const now = new Date();
    let age = now.getFullYear() - dob.getFullYear();
    if (now.getMonth() < dob.getMonth()
      || (now.getMonth() === dob.getMonth() && now.getDate() < dob.getDate())) age -= 1;

    if (age > 200 || age < 0) return "";
    return `${age} Jahre`;
  }
}
