import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    sortBy: String,
    sortByFallback: String,
    sortDirection: String,
  };

  static targets = ["cards", "sortByToggle", "sortDirectionToggle"];

  static get ASC() {
    return "asc";
  }

  static get DESC() {
    return "desc";
  }

  connect() {
    this.sortByToggleTargets[0].classList.remove("d-none");
    this.sortDirectionToggleTargets[0].classList.remove("d-none");
  }

  sortByToggleTargetConnected(element) {
    if (!this.sortByValue) {
      [this.sortByValue, this.sortByFallbackValue] = JSON.parse(element.dataset.boardSortableColumnSortBy);
    } else {
      element.classList.add("d-none");
    }
  }

  sortDirectionToggleTargetConnected(element) {
    if (!this.sortDirectionValue) {
      this.sortDirectionValue = element.dataset.boardSortableColumnSortDirection;
    } else {
      element.classList.add("d-none");
    }
  }

  toggleSortBy(event) {
    const target = event.currentTarget;
    const nextSibling = target.nextElementSibling || target.parentNode.firstElementChild;
    const allSiblings = target.parentNode.children;

    Array.from(allSiblings).forEach((sibling) => sibling.classList.add("d-none"));
    nextSibling.classList.remove("d-none");

    [this.sortByValue, this.sortByFallbackValue] = JSON.parse(nextSibling.dataset.boardSortableColumnSortBy);
  }

  toggleSortDirection(event) {
    const target = event.currentTarget;
    const nextSibling = target.nextElementSibling || target.parentNode.firstElementChild;
    const allSiblings = target.parentNode.children;

    Array.from(allSiblings).forEach((sibling) => sibling.classList.add("d-none"));
    nextSibling.classList.remove("d-none");

    this.sortDirectionValue = nextSibling.dataset.boardSortableColumnSortDirection;
  }

  sortByValueChanged(value, previousValue) {
    if (value) {
      this.element.querySelectorAll("*[data-sort-toggle]")
        .forEach((element) => element.classList.add("d-none"));
      this.element.querySelectorAll(`*[data-sort-toggle~='${value}']`)
        .forEach((element) => element.classList.remove("d-none"));

      if (previousValue) this.sort();
    }
  }

  sortDirectionValueChanged(value, previousValue) {
    if (value && previousValue) this.sort();
  }

  sort() {
    [...this.cardsTarget.children]
      .forEach((element) => element.classList.remove("board__card--not-sortable"));

    const sortedCards = [...this.cardsTarget.children]
      .sort((elementA, elementB) => {
        let a = elementA.getAttribute(`data-sort-${this.sortByValue}`);
        let b = elementB.getAttribute(`data-sort-${this.sortByValue}`);

        if (this.sortDirectionValue === this.constructor.DESC) {
          [a, b] = [b, a];
          [elementA, elementB] = [elementB, elementA];
        }

        if (a === null && b === null) {
          elementA.classList.add("board__card--not-sortable");
          elementB.classList.add("board__card--not-sortable");
          return 0;
        }

        if (a === null) {
          elementA.classList.add("board__card--not-sortable");
          return this.sortDirectionValue === this.constructor.DESC ? -1 : 1;
        }

        if (b === null) {
          elementB.classList.add("board__card--not-sortable");
          return this.sortDirectionValue === this.constructor.DESC ? 1 : -1;
        }

        if ((this.sortByFallbackValue !== undefined) && (a === b)) {
          a = elementA.getAttribute(`data-sort-${this.sortByFallbackValue}`);
          b = elementB.getAttribute(`data-sort-${this.sortByFallbackValue}`);
        }

        return Number.isNaN(a - b) ? a.localeCompare(b) : a - b;
      });

    this.cardsTarget.append(...sortedCards);
    this.element.scrollTo(0, 0);
  }
}
