import * as bootstrap from "bootstrap";

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("[data-bs-toggle=\"tooltip\"]").forEach((element) => {
    new bootstrap.Tooltip(element);
  });

  document.querySelectorAll("[data-bs-toggle=\"popover\"]").forEach((element) => {
    new bootstrap.Popover(element);
  });
});
