import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  modifiedClass = "is-modified";

  beforeUnloadEventListener(event) {
    event.preventDefault();
    event.returnValue = "";

    return event.returnValue;
  }

  inputEventListener() {
    this.element.classList.add(this.modifiedClass);
    window.addEventListener("beforeunload", this.beforeUnloadEventListener);
  }

  resetEventListener() {
    this.element.classList.remove(this.modifiedClass);
    window.removeEventListener("beforeunload", this.beforeUnloadEventListener);
    setTimeout(() => this.dispatch("reset"), 0);
  }

  submitEventListener() {
    window.removeEventListener("beforeunload", this.beforeUnloadEventListener);
  }

  connect() {
    Array.from(this.element.elements).forEach((element) => {
      // Apparently in some environments (e.g. capybara + selenium + headless chrome),
      // "input" events are not fired like they should on "select" elements. So falling
      // back to "change" events for those:
      if (element.tagName === "SELECT") {
        element.addEventListener("change", this.inputEventListener.bind(this));
      } else {
        element.addEventListener("input", this.inputEventListener.bind(this));
      }
    });
    this.element.addEventListener("reset", this.resetEventListener.bind(this));
    this.element.addEventListener("submit", this.submitEventListener.bind(this));
  }
}
