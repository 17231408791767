import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dueAt", "relativeDueAt"];

  connect() {
    this.update();
  }

  update() {
    this.relativeDueAtTarget.innerHTML = this.constructor.relativeDueAtString(this.dueAtTarget.value);
  }

  select(event) {
    const setValue = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this.dueAtTarget), "value").set;
    const inputEvent = new Event("input", { bubbles: true });

    setValue.call(this.dueAtTarget, event.params.date);
    this.dueAtTarget.dispatchEvent(inputEvent);
  }

  static relativeDueAtString(dateString) {
    if (!dateString) return "";

    const date = new Date(dateString).setHours(0, 0, 0, 0);
    const now = new Date().setHours(0, 0, 0, 0);
    const difference = Math.round((now - date) / 1000 / 60 / 60 / 24); // Round away daylight saving time shifts

    if (difference === -1) return "fällig in einem Tag";
    if (difference < 0) return `fällig in ${Math.abs(difference)} Tagen`;
    if (difference === -1) return "überfällig seit einem Tag";
    if (difference > 0) return `überfällig seit ${difference} Tagen`;

    return "heute fällig";
  }
}
