import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
  };

  initialize() {
    this.timeout = null;
  }

  show(event) {
    if (this.element.classList.contains("board__card__notes--loaded")) return;
    event.stopPropagation();
    this.timeout = setTimeout(this.loadNotes.bind(this), 250);
  }

  hide(event) {
    event.stopPropagation();
    clearTimeout(this.timeout);
    this.element.removeAttribute("title");
  }

  loadNotes() {
    this.constructor.fetchNotes(this.urlValue).then(this.showNotes.bind(this));
  }

  showNotes(data) {
    const notes = data.map((note) => {
      let text = `${note.user} vor ${note.date}`;

      if (note.has_content) {
        text = `${text}\n${note.content}`;
      }

      if (note.has_attachment) {
        text = `${text}\nAnhang: ${note.attachment}`;
      }

      return text;
    }).join("\n\n");

    this.element.classList.add("board__card__notes--loaded");
    this.element.querySelector(".board__card__notes__listing__content").innerHTML = notes;
  }

  static async fetchNotes(url = "") {
    const response = await fetch(url, {
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json();
  }
}
