import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    userId: Number,
  };

  initialize() {
    this.showAll = true;
    this.board = document.querySelector(".board");
  }

  toggle() {
    this.showAll = !this.showAll;
    this.filterCards();
  }

  filterCards() {
    if (this.showAll) {
      this.constructor.cards(this.board).forEach((card) => card.classList.remove("d-none"));
      this.element.classList.remove("btn-primary");
    } else {
      this.constructor.cards(this.board).forEach((card) => {
        if (!card.dataset.filterUserIds || JSON.parse(card.dataset.filterUserIds).indexOf(this.userIdValue) === -1) {
          card.classList.add("d-none");
        }
      });
      this.element.classList.add("btn-primary");
    }

    const notHidden = (card) => !card.classList.contains("d-none");

    this.constructor.columns().forEach((column) => {
      if (Array.from(this.constructor.cards(column)).some(notHidden)) {
        column.classList.remove("board__column--empty");
      } else {
        column.classList.add("board__column--empty");
      }
    });
  }

  static cards(parent) {
    return parent.querySelectorAll(".list-group-item");
  }

  static columns() {
    return document.querySelectorAll(".board__column");
  }
}
