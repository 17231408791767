import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["countries", "postcodes", "cities", "states"]; // Order is important

  multiselects = [null, null, null, null];

  connect() {
    [this.countriesTarget, this.postcodesTarget, this.citiesTarget, this.statesTarget]
      .forEach((target) => this.setupMultiselect(target));
  }

  setupMultiselect(target) {
    target.addEventListener("multiselect:ready", (event) => {
      const multiselect = event.detail;
      this.multiselects[this.constructor.targets.indexOf(multiselect.element.dataset.addressTarget)] = multiselect;
      if (!this.multiselects.includes(null)) this.updateMultiselectUrls();

      multiselect.tomselect.on("focus", () => multiselect.tomselect.load());

      multiselect.tomselect.on("item_add", () => {
        this.updateMultiselectUrls();
        if (multiselect.resetting) return;

        const nextMultiselects = this.getDependentMultiselects(multiselect);
        nextMultiselects.forEach((m) => {
          m.tomselect.clear();
          m.tomselect.clearOptions();
        });
        if (nextMultiselects[0]) nextMultiselects[0].tomselect.focus();
        else multiselect.tomselect.blur();
      });

      multiselect.tomselect.on("item_remove", () => {
        this.updateMultiselectUrls();
        if (multiselect.resetting) return;

        this.getDependentMultiselects(multiselect).forEach((m) => {
          m.tomselect.clear();
          m.tomselect.clearOptions();
          if (document.activeElement === m.tomselect.control_input) m.tomselect.load();
        });
      });
    });
  }

  getDependentMultiselects(multiselect) {
    return this.multiselects.slice(this.multiselects.findIndex((m) => m === multiselect) + 1);
  }

  updateMultiselectUrls() {
    const countryParam = this.constructor.buildParam("countries", this.multiselects[0].tomselect.getValue());
    const postcodeParam = this.constructor.buildParam("postcodes", this.multiselects[1].tomselect.getValue());
    const cityParam = this.constructor.buildParam("cities", this.multiselects[2].tomselect.getValue());

    this.multiselects[1].urlValue = this.constructor.buildLocationUrl("postcode", [countryParam]);
    this.multiselects[2].urlValue = this.constructor.buildLocationUrl("city", [countryParam, postcodeParam]);
    this.multiselects[3].urlValue = this.constructor
      .buildLocationUrl("state", [countryParam, postcodeParam, cityParam]);
  }

  static buildParam(paramName, value) {
    return value ? `${paramName}[]=${encodeURIComponent(value)}` : "";
  }

  static buildLocationUrl(selectField, params) {
    return `/locations.json?${params.filter(Boolean).join("&")}&multiselect=${selectField}&${selectField}_like=`;
  }
}
