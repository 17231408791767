import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["replica", "textArea"];

  connect() {
    this.update();
  }

  update() {
    // Trailing space needed, else trailing newline gets trimmed by browser
    this.replicaTarget.innerText = `${this.textAreaTarget.value} `;
  }
}
