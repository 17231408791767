import { Application } from "@hotwired/stimulus";
import AddressController from "../controllers/address_controller";
import AgeController from "../controllers/age_controller";
import AutoGrowTextAreaController from "../controllers/auto_grow_text_area_controller";
import BoardCardNotesController from "../controllers/board_card_notes_controller";
import BoardFilterCardsController from "../controllers/board_filter_cards_controller";
import BoardSortableColumnController from "../controllers/board_sortable_column_controller";
import DueAtController from "../controllers/due_at_controller";
import MultiselectController from "../controllers/multiselect_controller";
import NotesController from "../controllers/notes_controller";
import PayController from "../controllers/pay_controller";
import SafeFormController from "../controllers/safe_form_controller";

window.Stimulus = Application.start();
window.Stimulus.register("address", AddressController);
window.Stimulus.register("age", AgeController);
window.Stimulus.register("auto-grow-text-area", AutoGrowTextAreaController);
window.Stimulus.register("board-card-notes", BoardCardNotesController);
window.Stimulus.register("board-filter-cards", BoardFilterCardsController);
window.Stimulus.register("board-sortable-column", BoardSortableColumnController);
window.Stimulus.register("due-at", DueAtController);
window.Stimulus.register("multiselect", MultiselectController);
window.Stimulus.register("notes", NotesController);
window.Stimulus.register("pay", PayController);
window.Stimulus.register("safe-form", SafeFormController);
