import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filterCheckbox", "note"];

  filter() {
    this.noteTargets.forEach((element) => {
      if (!element.hasAttribute("data-with-attachment")) {
        if (this.filterCheckboxTarget.checked) {
          element.classList.add("d-none");
        } else {
          element.classList.remove("d-none");
        }
      }
    });
  }
}
