import * as Sentry from "@sentry/browser";

document.addEventListener("DOMContentLoaded", () => {
  const sentryDsn = document.querySelector("meta[name='sentry-dsn']")?.content;

  if (sentryDsn) {
    Sentry.init({
      dsn: sentryDsn,
      release: document.querySelector("meta[name='sentry-release']")?.content,
      initialScope: {
        user: {
          id: document.querySelector("meta[name='sentry-user-id']")?.content,
          username: document.querySelector("meta[name='sentry-user-username']")?.content,
        },
      },
    });
  }
});
