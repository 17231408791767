import { Controller } from "@hotwired/stimulus";
import Tomselect from "tom-select";

export default class extends Controller {
  static values = {
    options: Array,
    valueField: { type: String, default: "value" },
    labelField: { type: String, default: "label" },
    labelShortField: { type: String, default: "label_short" },
    allowCreate: Boolean,
    maxNumSelected: Number,
    url: String,
  };

  initialize() {
    if ((this.element.tagName !== "INPUT" || this.element.type !== "text") && this.element.tagName !== "SELECT") {
      throw new Error("Can only instantiate multiselect controller on <input type='text'> or <select> element");
    }

    const delimiter = this.maxNumSelectedValue === 1 ? null : ",";

    if (this.element.tagName !== "SELECT" && !this.optionsValue.length) {
      this.optionsValue = this.element.value.split(delimiter)
        .map((value) => ({ [this.valueFieldValue]: value, [this.labelFieldValue]: value }));
    }

    const settings = {
      maxOptions: 7,
      delimiter,
      maxItems: this.maxNumSelectedValue || null,
      openOnFocus: true,
      preload: "focus",
      closeAfterSelect: true,
      ...this.optionsValue.length && { options: this.optionsValue },
      labelField: this.labelFieldValue,
      valueField: this.valueFieldValue,
      create: this.allowCreateValue,
      searchField: [
        { field: this.labelShortFieldValue, weight: 10 },
        { field: this.labelFieldValue, weight: 1 },
      ],
      plugins: {
        remove_button: { title: "" },
      },
      placeholder: "Suche...",
      render: {
        item: (data, escape) => {
          let html = "<div class=\"d-flex lh-sm\">";
          if (data[this.labelShortFieldValue]) {
            html += `<div class="badge text-bg-secondary fw-normal p-1 me-2">
              ${escape(data[this.labelShortFieldValue])}</div>`;
          }
          html += `<div class="item-label">${escape(data[this.labelFieldValue])}</div>`;
          html += "</div>";
          return html;
        },
        option: (data, escape) => {
          let html = "<div class=\"d-flex lh-sm\">";
          if (data[this.labelShortFieldValue]) {
            html += `<div class="badge text-bg-secondary fw-normal p-1 me-2">
              ${escape(data[this.labelShortFieldValue])}</div>`;
          }
          html += `<div class="option-label">${escape(data[this.labelFieldValue])}</div>`;
          html += "</div>";
          return html;
        },
        option_create: (data, escape) => `<div class="create"><strong>${escape(data.input)}</strong> hinzufügen</div>`,
        no_results: () => "<div class=\"no-results\">Keine Ergebnisse</div>",
        loading: null,
      },
      load: (query, callback) => {
        if (this.urlValue) {
          fetch(this.urlValue + (query ? encodeURIComponent(query) : ""))
            .then((response) => response.json())
            .then((json) => {
              this.tomselect.clearOptions();
              callback(json);
            });
        }
      },
    };

    this.tomselect = new Tomselect(this.element, settings);

    if (this.element.form) {
      this.element.form.addEventListener("reset", this.resetEventListener.bind(this));
    }

    this.element.dispatchEvent(new CustomEvent("multiselect:ready", { detail: this }));
  }

  resetEventListener() {
    this.resetting = true;
    this.tomselect.clear(true);
    this.tomselect.clearOptions();
    setTimeout(() => {
      this.tomselect.addOptions(this.optionsValue);
      this.tomselect.sync();
      this.resetting = false;
    }, 0);
  }
}
